<template>
  <div>
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" y="0.5" width="39" height="39" rx="9.5" fill="#FAFCFD" stroke="black"/>
      <path
        d="M25.7611 16.2354C23.9761 16.2354 22.5225 14.837 22.5225 13.1177C22.5225 11.3984 23.9761 10 25.7611 10C27.5463 10 28.9998 11.3984 28.9998 13.1177C28.9998 14.837 27.5463 16.2354 25.7611 16.2354ZM25.7611 11.2471C24.6902 11.2471 23.8179 12.0859 23.8179 13.1177C23.8179 14.1495 24.6902 14.9883 25.7611 14.9883C26.832 14.9883 27.7043 14.1495 27.7043 13.1177C27.7043 12.0859 26.832 11.2471 25.7611 11.2471Z"
        fill="#00B6F8" stroke="#00B6F8"/>
      <path
        d="M25.7611 29.954C23.9761 29.954 22.5225 28.5556 22.5225 26.8362C22.5225 25.1169 23.9761 23.7185 25.7611 23.7185C27.5463 23.7185 28.9998 25.1169 28.9998 26.8362C28.9998 28.5556 27.5463 29.954 25.7611 29.954ZM25.7611 24.9656C24.6902 24.9656 23.8179 25.8044 23.8179 26.8362C23.8179 27.8681 24.6902 28.7069 25.7611 28.7069C26.832 28.7069 27.7043 27.8681 27.7043 26.8362C27.7043 25.8044 26.832 24.9656 25.7611 24.9656Z"
        fill="#00B6F8" stroke="#00B6F8"/>
      <path
        d="M13.2387 23.0949C11.4534 23.0949 10 21.6965 10 19.9772C10 18.2579 11.4534 16.8595 13.2387 16.8595C15.0239 16.8595 16.4773 18.2579 16.4773 19.9772C16.4773 21.6965 15.0239 23.0949 13.2387 23.0949ZM13.2387 18.1066C12.1678 18.1066 11.2955 18.9454 11.2955 19.9772C11.2955 21.009 12.1678 21.8478 13.2387 21.8478C14.3096 21.8478 15.1819 21.009 15.1819 19.9772C15.1819 18.9454 14.3096 18.1066 13.2387 18.1066Z"
        fill="#00B6F8" stroke="#00B6F8"/>
      <path
        d="M15.494 19.3694C15.2685 19.3694 15.0492 19.2563 14.9309 19.0552C14.7538 18.756 14.8618 18.3751 15.1727 18.2047L23.1864 13.8066C23.4964 13.6346 23.8928 13.7393 24.069 14.0395C24.2461 14.3388 24.1381 14.7195 23.8272 14.89L15.8134 19.288C15.7124 19.3428 15.6028 19.3694 15.494 19.3694Z"
        fill="#00B6F8" stroke="#00B6F8"/>
      <path
        d="M23.507 26.2289C23.3982 26.2289 23.2884 26.2023 23.1874 26.1467L15.1736 21.7486C14.8627 21.5782 14.7548 21.1974 14.9318 20.8981C15.1079 20.598 15.5036 20.494 15.8145 20.6653L23.8281 25.0633C24.139 25.2338 24.247 25.6145 24.0701 25.9138C23.9508 26.1158 23.7315 26.2289 23.507 26.2289Z"
        fill="#00B6F8" stroke="#00B6F8"/>
    </svg>
  </div>
</template>

<script>
import Vue from 'vue';

const IconSquareShare = Vue.extend({
  name: 'icon-square-share',
});

export default IconSquareShare;
</script>

<style>
</style>
