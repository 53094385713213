


import {Component, Vue, Watch} from 'vue-property-decorator';
import {Action, Getter} from 'vuex-class';
import {Validations} from 'vuelidate-property-decorators';
import {required} from 'vuelidate/lib/validators';
import {TCodeList, TPromoPage} from '@/_types/promo-page/promo-page.type';
import {TVuelidateRuleSet} from '@/_types/vuelitation-rule-set.type';
import ValidationHelper from '@/_helpers/validation.helper';
import ErrorInfo from '@/_modules/error-info/error-info.vue';

const duplicateError = 'ERROR: duplicate key value violates unique constraint "promopages_pk" (SQLSTATE 23505)';

type TCreateExponent = {
  title: string;
  code: string;
  external_id: string;
  owner_email?: string;
}

@Component({
  components: {
    ErrorInfo
  }
})
export default class CreateExponents extends Vue {
  @Action('promoPageStore/createPromoPage') createPromoPage: (promoPageData: any) => Promise<TPromoPage>;
  @Getter('promoPageStore/getListAllCodes') getListAllCodes: any;
  public duplicateExternalId: boolean = false;
  public createPromoPageError: any = null

  public get eventId(): number {
    return this.$route.params.eventId ? parseInt(this.$route.params.eventId, 10) : null;
  }

  @Validations()
  public readonly validations: TVuelidateRuleSet<TCreateExponent> = {
    formData: {
      title: {
        required,
      },
      external_id: {
        required,
        isValidExternalId(externalId: string): boolean {
          return ValidationHelper.isValidExternalId(externalId);
        },
        uniqueExternalId(externalId: string): boolean {
          return !this.findNotUnique(externalId);
        }
      },
      owner_email: {
        isValidEmail(email: string): boolean {
          if (email === '') {
            return true;
          }
          return ValidationHelper.isValidEmail(email);
        }
      },
    },
  }

  public findNotUnique(externalId: string): TCodeList {
    return this.getListAllCodes.find((id: TCodeList) => {
      return id.external_id === externalId || this.duplicateExternalId;
    });
  }

  public promoPageCodeList: TCodeList[];
  public formData: TCreateExponent = {
    title: '',
    code: '',
    external_id: '',
    owner_email: '',
  };

  @Watch('formData.title')
  private onFormDataTitleChange(): void {
    if (!this.formData.title) {
      this.formData.external_id = '';
    }
  }

  @Watch('formData.external_id')
  private onFormDataExternalIdChange(): void {
    this.formData.code = '';
  }

  private static randomChars(): string {
    let text = '';
    const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

    for (let i = 0; i < 3; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }

    return text;
  }

  private async postPromoPage(): Promise<void> {
    this.duplicateExternalId = false;
    this.createPromoPageError = null;
    this.generateAccessKey();

    if (this.$v.formData.$pending || this.$v.formData.$invalid || !this.formData.code) {
      return;
    }
    this.$v.$reset();
    const result = await this.createPromoPage({
      eventId: this.$route.params.eventId,
      promoPageData: this.getPreprocessedFormData(),
    });
    if ((result as any).error) {
      this.createPromoPageError = result;
    }
    if ((result as any).error === duplicateError) {
      this.duplicateExternalId = true;
      this.$v.formData.external_id.$touch();
      return;
    }
    this.clearFormData();
  }

  public getPreprocessedFormData(): TCreateExponent {
    const result: any = {};
    Object.keys(this.formData).forEach(formDataKey => {
      if (this.formData[formDataKey as keyof TCreateExponent]) {
        result[formDataKey as keyof TCreateExponent] = this.formData[formDataKey as keyof TCreateExponent];
      }
    });
    return result as TCreateExponent;
  }

  public clearFormData(): void {
    this.formData.title = '';
    this.formData.external_id = '';
    this.formData.code = '';
    this.formData.owner_email = '';
  }

  private generateAccessKey(): void {
    this.$v.formData.$touch();

    if (this.$v.formData.$pending || this.$v.formData.$invalid) {
      return;
    }

    this.formData.code = `${(~~(Math.random() * 1e8)).toString(19)}${CreateExponents.randomChars()}`;

  }

  private preview(): void {
    const routeData = this.$router.resolve({
      name: 'promo-page-events-companies',
      params: {
        eventId: this.$route.params.eventId
      }
    });
    window.open(routeData.href, '_blank');
  }
}
