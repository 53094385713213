


import {Component, Vue, Watch} from 'vue-property-decorator';
import {mapGetters} from 'vuex';
import IconCloseQrPopup from '@/_modules/icons/components/qrpopup/icon-close-qr-popup.vue';
import IconDownloadQrPopup from '@/_modules/icons/components/qrpopup/icon-download-qr-popup.vue';
import IconShareQrPopup from '@/_modules/icons/components/qrpopup/icon-share-qr-popup.vue';
import IconSquareDelete from '@/_modules/icons/components/icon-square-delete.vue';
import IconUpDownArrow from '@/_modules/icons/components/icon-up-down-arrow.vue';
import _cloneDeep from 'lodash.clonedeep';
import {TEventTag} from '@/_types/event-tag.type';

@Component({
  components: {
    IconCloseQrPopup,
    IconDownloadQrPopup,
    IconShareQrPopup,
    IconSquareDelete,
    IconUpDownArrow
  },
  computed: {
    ...mapGetters({
      eventTags: '_eventStore/eventTags',
    }),
  },
})
export default class CreateTagsPopup extends Vue {
  public isShowTagGroup: boolean = false;
  public isShowEmptyTagGroup: boolean = false;
  public newTag: TEventTag = {
    id: null,
    name: ''
  };
  public tempTagList: TEventTag[] = []
  public showTagGroupId: number = null
  public cloneEventTags: TEventTag[] = []

  private eventTags: TEventTag[];

  public isCategoriesSaved: boolean = false

  @Watch('isShowEmptyTagGroup', {immediate: true})
  public onIsShowEmptyTagGroupChanged(): void {
    if (this.isShowEmptyTagGroup) {
      this.$nextTick(() => {
        const ref = this.$refs['scroll-height'] as Vue;
        ref.$el.scrollTop = ref.$el.scrollHeight;
      });
    }
  }
  @Watch('eventTags', {immediate: true})
  public onEventTagsChanged(): void {
    this.cloneEventTags = _cloneDeep(this.eventTags);
  }

  public mounted(): void {
    this.getEventTags();
  }

  private emitClose(): void {
    this.$emit('closeTagPopup');
  }

  public get tagList(): TEventTag[] {

    const localTags = _cloneDeep(this.cloneEventTags);

    const root: TEventTag[] = [];
    const map: any = {};

    localTags.forEach(node => {
      if (node.parent_id === 0) {
        root.push(node);
        return;
      }

      let parentIndex = map[node.parent_id];
      if (typeof parentIndex !== 'number') {
        parentIndex = localTags.findIndex(el => el.id === node.parent_id);
        map[node.parent_id] = parentIndex;
      }

      if (!localTags[parentIndex].children) {
        localTags[parentIndex].children = [];
      }

      localTags[parentIndex].children.push(node);
    });

    return root;
  }

  public get eventId(): number {
    return (this.$route.params.eventId && parseInt(this.$route.params.eventId, 10)) || null;
  }

  public setColor(id: number): string {
    return (this.isShowTagGroup && id === this.showTagGroupId) ? '#00b6f8' : undefined;
  }

  private async getEventTags(): Promise<void> {
    await this.$store.dispatch('_eventStore/getEventTags', {eventId: this.eventId});
  }

  public async deleteTag(tag: TEventTag): Promise<void> {
    await this.$store.dispatch('_eventStore/removeEventTag', {
      eventId: this.eventId,
      tagId: tag.id
    });
    await this.getEventTags();
  }

  public async addNewSubTag(): Promise<void> {
    await this.$store.dispatch('_eventStore/createEventTag', {
      eventId: this.eventId,
      name: this.newTag.name
    });
    await this.getEventTags();
    const lastAddedTagId = this.tagList[this.tagList.length - 1].id;

    this.cloneEventTags.find((item, index) => {
      if (item.id === lastAddedTagId) {
        if (this.cloneEventTags[index].name) {
          this.cloneEventTags.push({
            name: '',
            parent_id: lastAddedTagId,
            id: null
          });
          this.newTag.name = '';
          this.isShowEmptyTagGroup = false;
          this.isShowTagGroup = true;
          this.showTagGroupId = item.id;
        }
      }
    });
  }

  public async addEmptySubTag(id: number, isRootTag: boolean): Promise<void> {
    if (isRootTag) {
      this.isShowTagGroup = true;
      this.showTagGroupId = id;
    }

    const lastAddedTagId = this.cloneEventTags[this.cloneEventTags.length - 1].id;

    this.cloneEventTags.find((item, index) => {
      if (item.id === lastAddedTagId) {
        if (item.name) {
          if (item.children && item.children.length) {
            this.tempTagList.push(item.children[item.children.length - 1]);
          }

          this.cloneEventTags.push({
            name: '',
            parent_id: id,
            id: null
          });
        } else {
          this.cloneEventTags.splice(index, 1);

          if (item.children && item.children.length) {
            this.tempTagList.push(item.children[item.children.length - 1]);
          }

          this.cloneEventTags.push({
            name: '',
            parent_id: id,
            id: null
          });
        }
      }

      if (item.name && !item.id) {
        this.tempTagList.push(item);
      }
    });
  }

  public async addSubTag(tag: TEventTag): Promise<void> {
    let children: TEventTag[] = [];
    if (tag.id && !tag.children) {
      await this.$store.dispatch('_eventStore/removeEventTag', {
        eventId: this.eventId,
        tagId: tag.id,
      });
    }

    if (tag.id && tag.children) {
      children = tag.children;

      await this.$store.dispatch('_eventStore/removeEventTag', {
        eventId: this.eventId,
        tagId: tag.id,
      });
    }

    const emptyTag = this.cloneEventTags.find(item => item.name === '');

    await this.postTag(tag.name, tag.parent_id);

    await this.getEventTags();

    const foundParent = this.cloneEventTags.find(item => item.name === tag.name);

    await Promise.all(children.map(async (content) => {
      if (content.name) {
        await this.postTag(content.name, foundParent.id);
      }
    }));

    await this.getEventTags();

    if (emptyTag) {
      this.cloneEventTags.push({
        name: '',
        parent_id: tag.parent_id,
        id: null
      });
    }

  }

  public async saveTags(): Promise<void> {

    if (this.newTag) {
      await this.postTag(this.newTag.name, null);
      this.newTag.name = '';
      this.isShowEmptyTagGroup = false;
    }

    await Promise.all(this.tempTagList.map(async (content) => {
      if (content.name && !content.id) {
        await this.postTag(content.name, content.parent_id);
      }
    }));

    await this.getEventTags();
  }

  public async onSaveButtonClick(): Promise<void> {
    await this.saveTags();
    this.isCategoriesSaved = true;
    window.setTimeout(() => {
      // this.emitClose(); TODO: close or not?
      this.isCategoriesSaved = false;
    }, 3500);
  }

  public async postTag(name: string, parentId: number): Promise<TEventTag> {
    if (!name) {
      return null;
    }

    return await this.$store.dispatch('_eventStore/createEventTag', {
      eventId: this.eventId,
      name: name,
      parent: parentId
    });
  }

  public isDisabled(id: number): boolean {
    return !!id;
  }

  public showTagGroup(id: number): void {
    this.showTagGroupId = id;
    if (id === this.showTagGroupId) {
      this.isShowTagGroup = true;
    } else {
      this.isShowTagGroup = !this.isShowTagGroup;
    }
  }

  public isShowTagGroupFullHeight(id: number): boolean {
    return (id === this.showTagGroupId) && this.isShowTagGroup;
  }
}
