<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" :style="rotate">
    <circle cx="12" cy="12" r="11.5" transform="rotate(-180 12 12)" :stroke="stroke"  fill="white"/>
    <polygon width="16" height="16" :fill="fill" transform="rotate(-45 10 11)"  points="8,16 16,16 8,8"/>
  </svg>
</template>

<script>
import Vue from 'vue';

const IconUpDownArrow = Vue.extend({
  name: 'icon-up-down-arrow',
  props: {
    color: {
      type: String,
      default: '#e3e9ee',
    },
  },
  computed: {
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    stroke() {
      return `${this.color}`;
    },
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    fill() {
      return `${this.color}`;
    },
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    rotate() {
      if (this.color !== '#e3e9ee') {
        return 'transform: rotate(180deg);';
      } else {
        return 'transform: rotate(0deg);';
      }
    },
  },
});
export default IconUpDownArrow;
</script>

<style scoped lang="scss">
/* stylelint-disable */

</style>
